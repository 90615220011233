// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';

@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import './assets/scss/generic.scss';
@import './assets/scss/smartlabel.scss';
@import url('./assets/scss/print.scss') print;

@import "~@ng-select/ng-select/themes/default.theme.css";
/*
------------------------------
 GLOBAL CSS
------------------------------
*/

:root{
  --ion-color-facebook: #3b5998;
  --ion-color-twitter: #00ACED;
  --ion-color-pinterest: #DD4B39;
  --ion-background-color: #ffffff;
  --ion-color-google: #db3236;

  .icon-facebook{
    color: var(--ion-color-facebook, inherit);
  }

  .icon-twitter{
    color: var(--ion-color-twitter, inherit);
  }

  .icon-pinterest{
    color: var(--ion-color-pinterest, inherit);
  }

}

body{
  background: var(--ion-background-color, #ffffff);
  background: #ffffff;
}

ion-item-divider{
  --background: var(--ion-background-color,#fff);
}

.w-100{
  width: 100% !important;
}

//Need to update this later based on number of fixed footers
app-main{
  ion-content{
    --padding-bottom: 90px;
  }
}

.no-border{
  border: none !important;
}

/*
------------------------------
 CUSTOM CSS
------------------------------
*/


.c-white{
    color: #fff!important;
    ion-toolbar{
      ion-text{
          color: #fff!important;
      }
    }
}

.c-qliktag{
  color: #00d392 !important;
  ion-toolbar{
    ion-text{
      color: #00d392 !important;
    }
  }
}


.ion-page{
  &.sandbox{
    zoom: 0.89;
  }
}


.no-support{
  fa-icon{
    font-size: 40px;
    color: #dddddd;
  }
  .browser-logos{
    a{
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}


@media all and (max-width: 480px) {
  .no-support{
    font-size: 14px;

    .browser-logos{
      a{
        img{
          width: 48px !important;
          height: 48px !important;
        }
      }
    }
  }

}


.action-sheet-group {
  overflow: auto !important;
}




/*
------------------------------
 APP LOADING CSS
------------------------------
*/
app-loading{
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  ion-spinner{
    width: 80px;
    height: 80px;
  }
}

/*
------------------------------
 HAMBURGER MENU CSS
------------------------------
*/

.hambuger_menu{

  ion-item{
    --padding-start: 0px;
    --inner-padding-start: 16px;
    &.active{
      --ion-background-color: #f5f5f5;
    }
  }
}


/*
------------------------------
 FORM CSS
------------------------------
*/
.form-group{
  margin-bottom: 15px;
}

.alert-radio-label.sc-ion-alert-md{
  white-space: normal;
}
.form-control{

  .native-input, .native-textarea{
    border-radius: 4px;
    border: 1px solid rgb(204, 204, 204);
    margin-top: 5px;

    &.focus{
      border: 1px solid darken(rgb(204, 204, 204), 10%);
    }
    
  }
 

}

ion-select{
  border-radius: 4px;
  border: 1px solid rgb(204, 204, 204);
  margin-top: 5px;

}

/*
------------------------------
 APP VIDEO CSS
------------------------------
*/

// app-video{
//   position: relative;
//   display: block;
//   width: 100%;

//   iframe{
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
  
//   }
// }

/*
------------------------------
 SOCIAL BUTTON  CSS
------------------------------
*/

.button-google,
.button-facebook,
.button-twitter{
  --color: #fff !important;

  &:focus,
  &:hover {
      --color: #fff;
      box-shadow: none;
      
     

  }

  
}

.button-google {
  --background: var(--ion-color-google) !important;
  --background-hover: var(--ion-color-google) !important;
  
}

.button-twitter {
  --background: var(--ion-color-twitter) !important;
  --background-hover: var(--ion-color-twitter) !important;
  
}

.button-facebook {
  --background: var(--ion-color-facebook) !important;
  --background-hover: var(--ion-color-facebook) !important;
  
}