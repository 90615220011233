/*
------------------------------
 SMARTLABEL CSS
------------------------------
*/

.smartlabel-page {
  padding-bottom: 60px; //to offset smartlabel's fixed footer
  /*
  ------------------------------
   SMARTLABEL HEADING
  ------------------------------
  */


  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Trebuchet MS' !important;
  }

}

.ion-color-smartlabel-blue {
  --ion-color-base: var(--ion-color-smartlabel-blue, #24338a) !important;
  --ion-color-base-rgb: var(--ion-color-smartlabel-bluer-rgb, 36, 51, 138) !important;
  --ion-color-contrast: var(--ion-color-smartlabel-blue-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-smartlabel-blue-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-smartlabel-blue-shade, #202d79) !important;
  --ion-color-tint: var(--ion-color-smartlabel-blue-tint, #3a4796) !important;
}

.smartlabel-header {
  --border-width: 0 0 1px 0;
  --border-color: var(--ion-color-smartlabel-green, #a1ce57);
  --background: url('/../assets/img/smartlabel-vector-logo.svg') no-repeat center center / 180px !important;

}

.smart-label-link {
  color: var(--ion-color-smartlabel-blue, inherit) !important;
}

.smarlabel-text {
  color: var(--ion-color-smartlabel-black, inherit) !important;
}

%header1 {
  font-size: 28px;
  font-weight: bold;
}

%paragraph {
  font-size: 20px;
  // font-weight: lighter;
}

.smartlabel-footer {
  text-align: center;
  font-size: 12px;
  border-bottom: none;
  font-family: var(--smartlabel-font-family, inherit) !important;

  .footer-text-wrapper {
    font-family: var(--smartlabel-font-family, inherit) !important;
  }

  .footer-ios {
    ion-toolbar {
      --border-width: 0;

    }
  }



}


.smartlabel-link {
  color: var(--ion-color-smartlabel-blue, inherit);
}

.footer-links {

  &.smartlabel-footer-links {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 15px;
    align-items: center;

    a {
      margin: 0;
      text-decoration: none;
      color: var(--ion-color-smartlabel-blue, inherit) !important;
      font-family: var(--smartlabel-font-family, inherit) !important;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      &:after {
        padding-left: 3px;
        padding-right: 3px;
        content: "|";
        color: var(--ion-color-smartlabel-blue, inherit);
        font-size: 18px;
        overflow: hidden;
        display: inline-block;
        vertical-align: middle;

      }

      &:last-child {
        &:after {

          color: #fff;
        }
      }
    }

  }

}





.smartlabel-footer-sticky {
  border-bottom: none;
  border-top: 1px solid var(--ion-color-smartlabel-green, #a1ce57);


  .social-links {
    display: flex;
    justify-content: center;

    a {

      padding: 0;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.smartlabel-heading {
  color: var(--ion-color-smartlabel-blue, inherit) !important;
  font-weight: bold;
  text-transform: capitalize;
}

.c-black {
  color: var(--ion-color-smartlabel-black, inherit) !important;
}

/*
------------------------------
 SMARTLABEL ALERT
------------------------------
*/

ion-alert {
  --max-width: 600px !important;
}


.smartlabel-alert {
  font-family: 'Trebuchet MS' !important;
  font-size: 15px;
  align-items: flex-start;

  .alert-wrapper {
    margin: 10px;
  }

  .alert-head {
    border-bottom: 1px solid #e5e5e5;

    h2 {
      font-weight: bold;
    }
  }

  .alert-button-group {
    border-top: 1px solid #e5e5e5;
  }

  .alert-message {
    padding-top: 20px !important;
    font-size: 14px;
    color: var(--ion-color-smartlabel-black, inherit) !important;
    line-height: 1.5;
  }

  .alert-button {
    &.sc-ion-alert-md {
      text-transform: capitalize;
      color: var(--ion-color-smartlabel-black, inherit) !important;
      border-radius: 6px;
      background-color: #fff;
      border: 1px solid #ccc;
      padding: 6px 12px;
      font-size: 14px;


      &:hover {
        background-color: #e6e6e6;
        border-color: #8c8c8c;
      }

    }

    &.smartlabel-alert-continue {
      color: #ffffff !important;
      background: var(--ion-color-smartlabel-blue, #3880ff);
      border-color: var(--ion-color-smartlabel-blue, #3880ff);

      &:hover {
        background-color: var(--ion-color-smartlabel-blue-shade, #3880ff);
        border-color: var(--ion-color-smartlabel-blue-shade, #3880ff);
      }
    }

  }

}

/*
------------------------------
 SMARTLABEL BANNER ALERT
------------------------------
*/

ion-item-divider {
  &.smartlabel-banner {
    --ion-background-color: var(--ion-color-smartlabel-green, #a1ce57);
    --ion-item-border-color: var(--ion-color-smartlabel-green, #a1ce57);

    ion-toolbar {
      --border-color: var(--ion-color-smartlabel-green, #a1ce57);
      --ion-toolbar-background: var(--ion-color-smartlabel-green, #a1ce57);
      --ion-toolbar-background: var(--ion-color-smartlabel-green, #a1ce57);
      font-size: 30px;
      font-weight: bold;
      letter-spacing: -0.5px;

      ion-label {
        --color: #fff !important;

        ion-icon {
          vertical-align: text-bottom;
          margin-right: 5px;
        }
      }

      ion-icon {
        color: #fff;
      }
    }

    .banner-link {
      color: #fff;
      display: block;
      font-size: 15px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;

      ion-icon {
        margin-left: 10px;
        font-size: 22px;
      }

      @media all and (max-width: 480px) {
        ion-icon {
          margin-left: 10px;
          font-size: 40px;
        }

      }


    }
  }


}



/*
------------------------------
 SMARTLABEL WRAPPER
------------------------------
*/

//NOTE: Add smartlabel-product-header class to product-header component

.product-header {
  font-family: var(--smartlabel-font-family, inherit) !important;

  h1 {
    margin: 0;
    font-weight: bold;
    letter-spacing: -1px;
  }

  .description {
    font-size: 12px;
  }

  .product-info {
    font-size: 12px;
  }

  .product-img-wrapper {
    width: 100%;
    min-height: 150px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    overflow: hidden;

  }


  app-smartlabel-selector {

    ion-select {
      max-width: 200px;
      padding: 5px 10px;
      font-size: 14px;
      font-family: var(--smartlabel-font-family, inherit) !important;
    }
  }
}

.variant-popover {
  font-family: var(--smartlabel-font-family, inherit);
  min-width: 300px;

  .popover-content {
    --box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
    font-size: 13px;
    padding: 10px;
    border-radius: 5px;
    --min-width: 260px;
    line-height: 18px;

  }
}



.smartlabel-variant-selector-popover {
  white-space: normal !important;
  font-size: 12px !important;
  white-space: normal !important;
  font-weight: normal;
  line-height: 18px;
  word-wrap: break-word !important;
  font-family: var(--smartlabel-font-family, inherit) !important;

  ion-list {

    ion-item {

      font-family: var(--smartlabel-font-family, inherit) !important;

      ion-label {
        font-family: var(--smartlabel-font-family, inherit) !important;
      }
    }
  }
}

/*
------------------------------
 SMARTLABEL TABS
------------------------------
*/


.smartlabel-tabs {
  --ion-item-border-color: #fff;
  --inner-padding-start: 0;
  --inner-padding-end: 0;

  ion-segment {
    align-items: stretch;



  }

  ion-segment-button {
    letter-spacing: normal;
    --background: var(--ion-color-smartlabel-blue, #24338a);
    --background-hover: var(--ion-color-smartlabel-blue, #24338a);
    --background-checked: #fff;
    --border-color: var(--ion-color-smartlabel-blue, #24338a);
    --color: #ffffff;
    --padding-top: 0px;
    --padding-bottom: 5px;
    border-top: 5px solid var(--ion-color-smartlabel-blue, #24338a);
    text-transform: none;
    --color-checked: var(--ion-color-smartlabel-blue, #24338a);
    --indicator-color-checked: #FFFFFF;
    --indicator-color-activated: #FFFFFF;
    flex-basis: 20%;
    min-width: 70px;
    // word-break: break-word;
    --padding-start: 5px;
    --padding-end: 5px;
    letter-spacing: -0.2px;
    --ripple-color: #ffffff;
    border-radius: 0;
    border-right: 1px solid #fff;
    display: inline-block;
    vertical-align: top;

    &:first-child,
    &:nth-child(2) {
      flex-basis: 18%;
    }

    &:nth-child(3) {
      flex-basis: 19%;
    }

    &:last-child {
      border-right: none !important;
    }


    &.activated,
    &.segment-button-checked {
      --background: #ffffff;
      --color-checked: var(--ion-color-smartlabel-blue, #24338a);
      --color-activated: var(--ion-color-smartlabel-blue, #24338a);
      --background-hover: #ffffff;
      --background-checked: #fff;
      --border-color: #ffffff;
      border-top: 5px solid var(--ion-color-smartlabel-green, #a1ce57);
    }

    ion-icon {
      font-size: 18px !important;
      margin-bottom: 10px;
    }

    ion-icon,
    fa-icon {
      font-size: 15px;

      margin-top: 10px;
    }



    ion-label {
      white-space: normal !important;
      font-size: 12px !important;
      white-space: normal !important;
      font-weight: normal;
      line-height: 18px;
      word-wrap: break-word !important;
      font-family: var(--smartlabel-font-family, inherit) !important;
    }

  }



  @media only screen and (max-width: 520px) {

    ion-segment-button {
      --padding-start: 0px;
      --padding-end: 0px;
    }

  }


}



/*
------------------------------
 SMARTLABEL CAROUSEL
------------------------------
*/

.smartlabel-carousel {

  .preview {
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .thumbnails {
    ion-thumbnail {
      &.active {
        border-color: var(--ion-color-smartlabel-blue, #24338a);
      }
    }
  }


}


/*
------------------------------
 CAROUSEL CSS
------------------------------
*/

.slideshow {
  .thumbnails {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;


    ion-thumbnail {
      --size: 80px;
      border: 2px solid #eee;
      margin: 5px;
    }
  }
}


/*
------------------------------
 SMARTLABEL FORM CONTROLS
------------------------------
*/


.smartlabel-form-control {

  &.smartlabel-dropdown {
    color: #fff;
    background: var(--ion-color-smartlabel-blue, #24338a);
    height: 36px;
    font-weight: bold;

    options {
      font-weight: 600;
    }
  }

}


/*
------------------------------
 SMARTLABEL LIST
------------------------------
*/

.smartlabel-list {
  font-family: var(--smartlabel-font-family, inherit) !important;

  app-list-item {
    .smartlabel-list {
      margin-left: 20px;
    }
  }

  ion-item {
    --min-height: 10px !important;
    //  --ion-item-color: var(--ion-color-smartlabel-blue, #24338a);
    --ion-item-border-color: #ddd;
    --detail-icon-color: var(--ion-color-smartlabel-blue, #24338a);
    --detail-icon-opacity: 1;
    font-family: var(--smartlabel-font-family, inherit) !important;
    white-space: normal;
    page-break-after: auto;



    &.p-end-0 {
      --padding-end: 0;
    }

    &.p-start-0 {
      --padding-start: 0;
    }

    &.inner-p-end-15 {
      --inner-padding-end: 15px;
    }

    &.inner-p-start-15 {
      --inner-padding-start: 15px;
    }

    ion-label {
      // margin-top: 10px !important;
      // margin-bottom: 10px !important;
      font-family: var(--smartlabel-font-family, inherit) !important;
      white-space: pre-wrap !important;
      font-size: inherit !important;



      h2 {
        font-weight: bold;
        white-space: normal;
        color: var(--ion-color-smartlabel-blue, #24338a);
        font-size: 18px;

      }

      p {
        white-space: pre-wrap;
        font-size: inherit;

      }

      .coc-text {
        float: right;
        color: var(--ion-color-smartlabel-black, #231f20);
      }
    }

    ion-text {
      --ion-color-base: var(--ion-color-smartlabel-black, #231f20);
    }

    .ng-fa-icon {
      color: var(--ion-color-smartlabel-blue, #24338a);
    }

    .smartlabel-certification-logo {
      max-width: 50px;
      margin-bottom: 5px;
    }

    .smartlabel-certification-dummy {
      display: inline-block;
      width: 50px;
      margin-right: 10px;
    }


  }

  //DRUG FACTS PANEL
  &.drug-facts {
    --ion-item-background: var(--ion-color-smartlabel-blue, #24338a);

    ion-item {
      --ion-item-border-color: transparent;

      .ng-fa-icon {
        color: #fff;
      }

      ion-label {
        h2 {
          color: #fff;
        }
      }
    }
  }

}


.smartlabel-disclosure-list {
  list-style: none outside;
  padding-left: 10px;

  li {
    position: relative;
    padding-left: 20px;

    label {
      padding-left: 0;
    }

    input[type="checkbox"] {
      pointer-events: none;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.smartlabel-ingredient-heading {
  color: var(--ion-color-smartlabel-green, #a1ce57);
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;

}

/*
------------------------------
 SMARTLABEL BUTTON SEGMENTS
------------------------------
*/

.smartlabel-button-segments {

  ion-segment-button {
    letter-spacing: normal;
    --background: #fff;
    --background-hover: #fff;
    --border-color: #fff;
    --color: var(--ion-color-smartlabel-blue, #24338a);
    --padding-top: 10px;
    --padding-bottom: 10px;
    --color-checked: var(--ion-color-smartlabel-blue, #24338a);
    --indicator-color-checked: #FFFFFF;
    --indicator-color-activated: #FFFFFF;
    --ripple-color: #ffffff;
    font-size: 20px;
    text-transform: none;


    &.activated,
    &.segment-button-checked {
      --background: #ffffff;
      --color-checked: var(--ion-color-smartlabel-blue, #24338a);
      --color-activated: var(--ion-color-smartlabel-blue, #24338a);
      --background-hover: #ffffff;
      --background-checked: #ffffff;
    }

    ion-label {
      margin-top: 5px;
    }

    fa-icon {
      .fa-phone {

        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
      }
    }


  }

}



/*
------------------------------
 SMARTLABEL JUMBOTRON
------------------------------
*/


.smartlabel-jumbotron {
  font-family: 'Trebuchet MS' !important;
  background: var(--ion-color-smartlabel-blue, #24338a);
  color: #fff;
  line-height: 1.5;
  letter-spacing: -1px;

  &.inverse {
    background: #fff;
    color: var(--ion-color-smartlabel-blue, #24338a);
  }

  .jumbotron-heading {
    @extend %header1;
  }

  .jumbotron-content {
    @extend %paragraph;
  }


  @media all and (max-width: 490px) {
    .jumbotron-heading {
      font-size: 22px;
    }

    .jumbotron-content {
      font-size: 18px;
    }

  }


}


/*
------------------------------
 SMARTLABEL RICH-TEXT
------------------------------
*/


.smartlabel-rich-text {
  font-family: var(--smartlabel-font-family, inherit) !important;

  li {
    margin-bottom: 10px;
  }

  a {
    color: var(--ion-color-smartlabel-blue, #24338a);
  }
}


/*
------------------------------
 SMARTLABEL TOOLTIP
------------------------------
*/

.smartlabel-tooltip {
  --background: rgba(0, 0, 0, 0.9);


}

/*
------------------------------
 SMARTLABEL BADGE
------------------------------
*/
.smartlabel-badge {
  --background: var(--ion-color-smartlabel-blue, #24338a);
  vertical-align: bottom;
}


/*
------------------------------
 SMARTLABEL FOOD 
------------------------------
*/

.list-ios {
  margin-bottom: 5px !important;
}


.smartlabel-selector {
  max-width: 520px;
  margin: 0 auto;
  margin-top: 15px;
  background: var(--ion-color-smartlabel-blue, #24338a);
  color: #fff;
}

ion-popover {
  ion-label {
    white-space: normal !important;
  }
}

.smartlabel-nutrition-count {
  max-width: 560px;
  margin: 5px auto;
  margin-top: 15px;

  .smartlabel-separator {
    max-width: 520px;
    background: #ddd;
    margin: 15px auto;
  }

  .count {
    text-align: center;
    border-right: 1px solid var(--ion-color-smartlabel-green, #a1ce57);
    height: 100%;

    span {
      font-size: 30px;
      font-weight: bold;
      line-height: normal;
      color: var(--ion-color-smartlabel-blue, #24338a);
    }

    .para {
      font-size: 16px;
      margin-top: 5px;
    }
  }

  ion-col {

    &:last-child {
      .count {

        border-right: none;
      }
    }
  }
}

.smartlabel-nutrition-table {
  border: 3px solid var(--ion-color-smartlabel-blue, #24338a);
  max-width: 520px;
  margin: 0 auto;
  padding: 5px;
  padding-bottom: 0px;
  color: var(--ion-color-smartlabel-blue, #24338a);

  h2 {

    &.table-header {
      color: var(--ion-color-smartlabel-blue, #24338a);
      font-size: 30px;
      font-weight: bold;

    }

  }

  .nutrient-facts {
    padding: 0;
    margin-bottom: 1px;
  }

  ion-list {

    ion-item {
      --ion-item-border-color: var(--ion-color-smartlabel-blue, #24338a);
      --inner-border-width: 0;
      --border-width: 0 0 2px 0;
      --padding-start: 0px;
      --padding-end: 0px;
      --inner-padding-end: 0;

      &.smartlabel-border-width-2x {
        --border-width: 0 0 4px 0;

      }

      &.smartlabel-border-width-4x {
        --border-width: 0 0 8px 0;

      }

      &.no-border {
        --ion-item-border-color: #ffffff;
      }

      ion-label {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
      }


      ion-note {
        color: var(--ion-color-smartlabel-blue, #24338a);
        padding: 0;
        font-size: 15px;
        font-weight: normal;

        &.smartlabel-border-width-4x {
          border-bottom: 4px solid var(--ion-color-smartlabel-blue, #24338a);
        }

        &.nutrient-name {
          max-width: 400px;
          white-space: normal;
        }

      }

      &.offset-15 {
        --padding-start: 15px;
      }

      &.offset-30 {
        --padding-start: 30px;
      }

      &.text-bold {
        ion-note {
          font-weight: bold;
        }
      }

      &.serving-size {
        ion-label {
          margin: 4px 0 !important;
        }


      }

      &.calorie-count,
      &.serving-size {
        span {

          font-weight: bold;

        }

        ion-label {
          margin: 4px 0 !important;

        }

        .value {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: flex-end;
        }
      }


    }

  }
}


/*
------------------------------
 SMARTLABEL FOOD ACC BADGES
------------------------------
*/
.smartlabel-acc-badges {
  margin-bottom: 10px;
  margin-left: 16px;
  overflow-x: auto !important;

  ion-segment-button {
    --padding-start: 0;
    --padding-end: 0;
    --indicator-color-checked: #fff;
    --border-color: #ffffff;
    --background-checked: #fff !important;
  }

  ion-thumbnail {
    --size: 60px;
    --border-color: #ffffff;
    --background-checked: #fff;
  }




}

.popover-content.sc-ion-popover-ios{
  overflow: visible !important;
}

.popover-arrow.sc-ion-popover-ios{
  display: none;
}


@media (max-width: 480px) {
  .smartlabel-acc-badges {


    ion-segment-button {
      width: 125px;
      height: 125px;
    }

    ion-thumbnail {
      --size: 90px;

    }

    .badge-align {
      max-width: 125 !important;
    }

  }
}

/*
-----------------------------------
 SMARTLABEL SUPPLEMENT FACTS TABLE
-----------------------------------
*/
.supplement-facts {
  ion-item {
    ion-grid {
      --ion-grid-padding: 0px;

      ion-col {
        --ion-grid-column-padding: 0px;
        color: var(--ion-color-smartlabel-blue, #24338a);
        font-size: 15px;
        font-weight: normal;
      }
    }
  }

}


/*
------------------------------
 SMARTLABEL SUPPLEMENT INGREDIENT LIST
------------------------------
*/

.smartlabel-list {
  &.ingredient-list {

    ion-item {
      --inner-padding-start: 15px;
    }


    &.nested-ingredients-list {
      padding-top: 0;
      padding-bottom: 0;
      margin-left: 0;

      ion-item {
        --inner-padding-start: 24px;


      }

      .nested-ingredients-list {

        ion-item {
          --inner-padding-start: 36px;
        }

        .nested-ingredients-list {

          ion-item {
            --inner-padding-start: 48px;
          }

          .nested-ingredients-list {

            ion-item {
              --inner-padding-start: 60px;
            }
          }
        }
      }
    }
  }
}


.certifications-modal {
  --height: auto;
  --min-height: 250px;
}
